import React, { useEffect } from "react"
import styled from "styled-components"
import InputSimulation from "../InputSimulation"
import { calculateSimulationByYear } from "../logic"
import currency from "currency.js"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Slider, Switch } from "@mui/material"
import { getUSDRate } from "../../../services/fixer"
import { openCheckoutSession } from "../../../services/stripe"
import { useQuery } from "../hooks/useQuery"
import Tooltip from "../../layout/shared/tooltip"
import { rates } from "../rates"

export default function ConfigSimulator() {
  let query = useQuery()
  const user = useSelector((state) => state.user)
  const totalTokens = useSelector((state) => state.user.report?.totalTokens)
  const [usdCurrency, setUsdCurrency] = useState(false)
  const [initialPayment, setInitialPayment] = useState(0)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [increase, setIncrease] = useState(0)
  const [simulationResult, setSimulationResult] = useState([])
  const [yearToFilter, setYearToFilter] = useState(20)
  const [yearsToFirstApartment, setYearsToFirstApartment] = useState(0)
  const [subscriptionError, setSubscriptionError] = useState(false)
  const [USDRate, setUSDRate] = useState(0)
  const [hideInitialPayment, setHideInitialPayment] = useState(false)

  const MINIMUM_TOKEN_MXN = 80000
  const MINIMUM_SUBSCRIPTION_MXN = 2000
  const MINIMUM_TOKEN_USD = 3950
  const MINIMUM_SUBSCRIPTION_USD = 115

  useEffect(() => {
    setHideInitialPayment(query.get("subscription"))
    const getExchangeReate = async () => {
      const exchangeRate = await getUSDRate(user.token)
      setUSDRate(exchangeRate)
    }
    if (user.token) {
      getExchangeReate()
    }
  }, [user])

  useEffect(() => {
    const simulation = calculateSimulationByYear(
      monthlyPayment,
      increase,
      initialPayment,
      totalTokens ? totalTokens : 0,
      usdCurrency ? "USD" : "MXN"
    )
    if (simulation.length > 0) {
      setSimulationResult(simulation)
      const timeToFirstApartment = simulation.find(
        (result) => result.ownedTokens >= 40
      )
      setYearsToFirstApartment(timeToFirstApartment?.year)
    }
  }, [monthlyPayment, increase, initialPayment, totalTokens, usdCurrency])

  useEffect(() => {
    if (
      (monthlyPayment > 0 &&
        monthlyPayment < MINIMUM_SUBSCRIPTION_MXN &&
        !usdCurrency) ||
      (monthlyPayment > 0 &&
        monthlyPayment < MINIMUM_SUBSCRIPTION_USD &&
        usdCurrency)
    ) {
      setSubscriptionError(true)
    } else {
      setSubscriptionError(false)
    }
  }, [monthlyPayment])

  const handleCurrencyChange = () => {
    if (usdCurrency) {
      setInitialPayment(initialPayment * USDRate)
      setMonthlyPayment(monthlyPayment * USDRate)
      setIncrease(increase * USDRate)
    } else {
      setInitialPayment(initialPayment / USDRate)
      setMonthlyPayment(monthlyPayment / USDRate)
      setIncrease(increase / USDRate)
    }

    setUsdCurrency(!usdCurrency)
  }

  const onHandleStripeSubscription = async () => {
    const monthlyAmount = usdCurrency
      ? monthlyPayment * USDRate
      : monthlyPayment
    const { data } = await openCheckoutSession(
      user.token,
      initialPayment,
      monthlyAmount,
      "subscription",
      user.id
    )
    const urlStripe = data.url
    window.open(urlStripe, "_self")
  }

  const onHandleStepInitialPayment = (e) => {
    if (!usdCurrency && e.target.value <= MINIMUM_TOKEN_MXN) {
      setInitialPayment(
        Math.round(e.target.value / MINIMUM_TOKEN_MXN) * MINIMUM_TOKEN_MXN
      )
    } else if (usdCurrency && e.target.value <= MINIMUM_TOKEN_USD) {
      setInitialPayment(
        Math.round(e.target.value / MINIMUM_TOKEN_USD) * MINIMUM_TOKEN_USD
      )
    } else {
      setInitialPayment(e.target.value)
    }

    // if (usdCurrency && e.target.value <= MINIMUM_TOKEN_USD) {
    //   setInitialPayment(
    //     Math.round(e.target.value / MINIMUM_TOKEN_USD) * MINIMUM_TOKEN_USD
    //   )
    // } else {
    //   setInitialPayment(e.target.value)
    // }

    // if (e.target.value <= 55680 && !usdCurrency) {
    //   console.log("MXN")
    // } else if (e.target.value <= 3198 && usdCurrency) {
    //   console.log("MXN")
    // } else {
    //   setInitialPayment(e.target.value)
    // }
  }

  const onHandleStepMonthlyPayment = (e) => {
    if (!usdCurrency && e.target.value <= MINIMUM_SUBSCRIPTION_MXN) {
      setMonthlyPayment(
        Math.round(e.target.value / MINIMUM_SUBSCRIPTION_MXN) *
          MINIMUM_SUBSCRIPTION_MXN
      )
    } else if (usdCurrency && e.target.value <= MINIMUM_SUBSCRIPTION_USD) {
      setMonthlyPayment(
        Math.round(e.target.value / MINIMUM_SUBSCRIPTION_USD) *
          MINIMUM_SUBSCRIPTION_USD
      )
    } else {
      setMonthlyPayment(e.target.value)
    }
  }
  return (
    <Container>
      <SectionLeft>
        <h2>Configura tu plan de compra</h2>
        <StartSimulation>
          <div className="container-setup">
            {!hideInitialPayment && (
              <div>
                <div className="container-input">
                  <h3>
                    Compra Inicial{" "}
                    <Tooltip
                      dark
                      content="Este monto indica cuanto pagarías para tu primer compra de m2. Eres libre de escoger cualquier monto."
                    />
                  </h3>
                  <InputSimulation
                    value={initialPayment}
                    updateState={setInitialPayment}
                    type={usdCurrency}
                  />
                </div>
                <Slider
                  min={0}
                  max={usdCurrency ? 58000 : 999000}
                  size="medium"
                  defaultValue={0}
                  aria-label="Medium"
                  step={100}
                  onChange={onHandleStepInitialPayment}
                />
              </div>
            )}
            <div>
              <div className="container-input">
                <h3>
                  Compra Mensual{" "}
                  <Tooltip
                    dark
                    content="Este monto indica cuanto estás dispuesto abonar cada mes para ir adquiriendo más m2. Una vez activa. Puedes modificar o pausar tu compra mensual en cualquier momento."
                  />
                </h3>
                <InputSimulation
                  value={monthlyPayment}
                  updateState={setMonthlyPayment}
                  type={usdCurrency}
                />
              </div>
              <Slider
                min={0}
                max={usdCurrency ? 3000 : 40000}
                size="medium"
                defaultValue={0}
                step={100}
                aria-label="Medium"
                onChange={onHandleStepMonthlyPayment}
              />
            </div>
          </div>
          <div className=" container-setup--two">
            <div>
              <h3 className="yearly">
                Incremento Anual de la compra mensual{" "}
                <Tooltip
                  dark
                  content="Este monto indica cuanto le aumentaras cada año a tu compra mensual."
                />
              </h3>
              <div className="container-input">
                <InputSimulation
                  value={increase}
                  updateState={setIncrease}
                  type={usdCurrency}
                />
              </div>
            </div>
            <div className="container-tokens">
              <h3>
                m<sup>2</sup> Actuales{" "}
                <Tooltip
                  dark
                  content="Este número indica cuantas m2 tienes actualmente."
                />
              </h3>
              <span className="tokens">{totalTokens ?? 0}</span>
            </div>
          </div>
        </StartSimulation>
      </SectionLeft>
      <SectionRight>
        <Projection>
          <div className="container-range">
            <h2>
              En <span className="years">{yearToFilter}</span> años tendrás
            </h2>
            <Slider
              min={1}
              max={20}
              size="medium"
              defaultValue={20}
              aria-label="Medium"
              onChange={(e) => {
                setYearToFilter(e.target.value)
              }}
            />
          </div>
          <div className="container-results">
            <div className="results">
              <h3>Ganancias anuales</h3>
              <div>
                <p className="text-left">
                  {currency(
                    simulationResult.length &&
                      simulationResult[yearToFilter].yearRent,
                    { precision: 0 }
                  ).format()}
                </p>
                <p className="light-gray">
                  (
                  {currency(
                    simulationResult.length &&
                      simulationResult[yearToFilter].yearRent / 12,
                    { precision: 0 }
                  ).format()}{" "}
                  al mes)
                </p>
              </div>
            </div>
            <div className="results">
              <h3>
                Valor de m<sup>2</sup>
              </h3>
              <p>
                {currency(
                  simulationResult.length &&
                    simulationResult[yearToFilter].totalTokensValue,
                  { precision: 0 }
                ).format()}
              </p>
            </div>
            <div className="other-results">
              <div>
                <h3>Pagaste en total</h3>
                <p>
                  {currency(
                    simulationResult.length &&
                      simulationResult[yearToFilter].totalInvestment,
                    { precision: 0 }
                  ).format()}
                </p>
              </div>
              <div>
                <h3>
                  m<sup>2</sup>
                </h3>
                <p className="right">
                  {(
                    simulationResult.length &&
                    simulationResult[yearToFilter].ownedTokens
                  ).toFixed(0)}
                </p>
              </div>
            </div>

            <div className="rendimientos">
              <article>
                <h4>{rates[yearToFilter - 1][0]}%</h4>
                <p>Rendimiento Anual de Rentas</p>
              </article>
              <article>
                <h4>{rates[yearToFilter - 1][1]}%</h4>
                <p>Plusvalía Anual</p>
              </article>
              <article>
                <h4>{rates[yearToFilter - 1][2]}%</h4>
                <p>Rendimiento Anual Total</p>
              </article>
            </div>
          </div>

          <div className="active-plan">
            {(initialPayment === 0 && monthlyPayment === 0) ||
            (initialPayment === 0 && monthlyPayment > 0) ? (
              <button
                onClick={
                  monthlyPayment > 0 && initialPayment === 0
                    ? onHandleStripeSubscription
                    : undefined
                }
                disabled={
                  (initialPayment === 0 && monthlyPayment === 0) ||
                  (initialPayment === 0 &&
                    monthlyPayment < MINIMUM_SUBSCRIPTION_MXN &&
                    !usdCurrency) ||
                  (initialPayment === 0 &&
                    monthlyPayment < MINIMUM_SUBSCRIPTION_USD &&
                    usdCurrency) ||
                  subscriptionError
                }
              >
                CONTINUAR CON ESTE PLAN
              </button>
            ) : (
              <Link
                to={
                  (initialPayment < MINIMUM_TOKEN_MXN && !usdCurrency) ||
                  (initialPayment < MINIMUM_TOKEN_USD && usdCurrency)
                    ? `/resumen?initial=${initialPayment}&monthly=${monthlyPayment}&usd=${usdCurrency}`
                    : `/selecciona-fracciones?initial=${initialPayment}&monthly=${monthlyPayment}&usd=${usdCurrency}`
                }
              >
                <button disabled={subscriptionError}>
                  CONTINUAR CON ESTE PLAN
                </button>
              </Link>
            )}
            {subscriptionError && (
              <p className="subscription-error">
                Tu suscripción debe ser mínimo de{" "}
                {usdCurrency ? (
                  <span>
                    {currency(MINIMUM_SUBSCRIPTION_USD, {
                      precision: 0
                    }).format()}
                  </span>
                ) : (
                  <span>
                    {currency(MINIMUM_SUBSCRIPTION_MXN, {
                      precision: 0
                    }).format()}
                  </span>
                )}{" "}
                {usdCurrency ? "USD" : "MXN"}
              </p>
            )}
            <div className="border"></div>
          </div>
        </Projection>
        <Details>
          <div className="container-info-currency">
            <div>
              <p className="label">Compra en MXN expresada en:</p>
              <div>
                <span>MXN</span>
                <Switch
                  onChange={() => {
                    handleCurrencyChange()
                  }}
                  color="warning"
                />
                <span>USD</span>
              </div>
            </div>
            <div>
              <p className="label">Tipo de cambio MXN / USD</p>
              <span className="value">${USDRate.toFixed(2)}</span>
            </div>
          </div>
          <p>
            El presente simulador ofrece una representación de las ganancias
            potenciales y del valor estimado de su patrimonio inmobiliario con
            el transcurso del tiempo, basándose en la selección efectuada por el
            usuario. Se ha tenido en cuenta el efecto compuesto que resulta de
            reinvertir las ganancias obtenidas de las rentas para adquirir más m
            <sup>2</sup> de edificios, brindando una proyección de posibles
            ingresos por rentas en el futuro.
          </p>
          <p>
            Es crucial entender que estas proyecciones están fundamentadas en
            datos históricos y se presentan exclusivamente con fines
            informativos. No existe garantía de que estas estimaciones reflejen
            los resultados reales en el futuro.
          </p>
          <p>
            La proyección se formula considerando un incremento anual del 6.00%
            en las rentas y una apreciación anual del 6.00% en el precio de cada
            fracción de los edificios. La base del cálculo presume una ganancia
            de rentas inicial de $220 MXN por fracción cada mes y un precio por
            fracción de $55,000 MXN, estableciendo que 1 fracción equivale a 1
            m².
          </p>
          <p>
            Aunque la compra de m<sup>2</sup> y las rentas se manejan en pesos
            mexicanos, pueden ser expresadas en USD en la presente tabla con
            fines informativos y para facilitar el entendimiento del usuario.
          </p>
          <p>
            (4) El rendimiento presentado está basado en la compra inicial que
            ha cubierto el período total considerado. Las compras mensuales
            subsecuentes no han tenido el mismo plazo que la compra inicial y,
            por lo tanto, no reflejan el rendimiento total en el período actual.
            Sin embargo, se espera que, una vez que estas compras mensuales
            cumplan el mismo plazo que la compra inicial, muestren un
            rendimiento similar, asumiendo condiciones de mercado consistentes.
          </p>
          <p>
            Si bien las rentas se reinvierten generando un efecto compuesto, el
            rendimiento mostrado se basa en un cálculo simple: la suma de rentas
            y plusvalía sobre la inversión inicial. Por lo tanto, el porcentaje
            representa directamente la relación entre ganancias totales y el
            monto invertido inicialmente.
          </p>
        </Details>
      </SectionRight>
    </Container>
  )
}

const Container = styled.div`
  h2 {
    text-align: center;
    font-weight: 400;
  }

  h3 {
    color: #fecfa4;
    font-size: 20px;
    font-weight: 400;
  }

  .error {
    color: red !important;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    @page {
      size: A4;
      margin: 0;
    }
    button {
      display: none;
    }
  }
`

const SectionLeft = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 1.5rem;
  position: relative;

  h2 {
    font-size: 2rem;
    font-family: "calibre";
    font-weight: 400;
  }

  @media (min-width: 1023px) {
    margin: 0 auto;
  }
`

const StartSimulation = styled.div`
  display: flex;
  flex-direction: column;

  .container-setup {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: #616b63;
    padding-bottom: 15px;

    h3 {
      font-size: 1.2rem;
      width: 40%;
      color: #000;
      font-family: "calibre";
    }

    .MuiSlider-root {
      color: #f9a14d;
      padding: 0;
    }
    .container-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      /* span {
        color: black;
        font-size: 2.5rem;
      } */

      input {
        font-size: 2.5rem;
      }

      input::placeholder {
        font-size: 2.5rem;
      }
    }
  }

  .container-setup--two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0px;
    padding-top: 15px;
    border: none;

    input {
      width: 5rem;
    }

    h3 {
      font-size: 17px;
      color: #000;
      font-family: "calibre";
    }

    .yearly {
      width: 65%;
      margin-bottom: 10px;
    }

    .container-tokens {
      h3 {
        color: #aaa;
        font-family: "calibre";
      }
      .tokens {
        display: block;
        text-align: right;
        font-weight: 400;
        color: #aaa;
        font-size: 25px;
        font-family: "calibre";
        /* padding-right: 10px; */
      }
    }
  }

  @media print {
    .container-inputs {
      justify-content: space-around;
      padding-bottom: 2rem;
    }

    .container-inputs--two {
      padding-top: 2rem;
    }
  }

  @media (min-width: 1024px) {
    .container-setup--two {
      .yearly {
        width: 55%;
      }
    }
  }
`

const SectionRight = styled.section`
  background-color: #38493c;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1.5rem;

  @media (min-width: 1024px) {
    gap: 2rem;
    .container {
      padding-bottom: 0;
    }
  }
`

const Projection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* padding: 0 2rem; */
  /* padding-bottom: 2rem; */
  /* border-bottom: 1px dashed #aaa; */

  h2 {
    text-align: center;
    font-size: 2rem;
    font-family: "calibre";
  }

  .container-range {
    display: flex;
    flex-direction: column;
    width: 20rem;
    gap: 2rem;
    margin: 0 auto;
    justify-content: center;

    .years {
      color: #f9a14d;
    }

    .MuiSlider-root {
      color: #f9a14d;
      padding: 0;
    }
    span {
      text-align: center;
      /* font-size: 1.5rem; */
      font-weight: 400;
    }
  }

  .container-results {
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    .results {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        color: white;
        font-size: 1.5rem;
        font-family: "calibre";
      }

      p {
        margin: 0;
        font-size: 25px;
      }
    }
    .other-results {
      display: flex;
      justify-content: space-between;
      h3 {
        color: #aaaaaa;
        font-size: 1.3rem;
        font-family: "calibre";
      }
      p {
        color: #aaaaaa;
        margin: 0;
        font-size: 1.3rem;
      }
      .right {
        text-align: right;
      }
    }

    .light-gray {
      color: #aaaaaa;
      margin: 0;
    }

    .text-left {
      text-align: right;
    }
  }

  .active-plan {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    button {
      background-color: #fecfa4;
      padding: 15px 28px;
      font-weight: bold;
      border-radius: 30px;
      font-family: "calibre";
      font-size: 1rem;
      border: none;
      color: black;
      cursor: pointer;
    }

    button:hover {
      background-color: #f9c27a;
    }

    button:disabled {
      background-color: gray;
      cursor: not-allowed;
    }

    .subscription-error {
      margin: 0;
      color: red;
    }

    .border {
      width: 100%;
      height: 1px;
      border-bottom: 2px dashed #aaa;
    }
  }

  .rendimientos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-top: 1px solid white;
    padding-top: 2rem;

    h4 {
      text-align: center;
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }

    p {
      text-align: center;
    }
  }

  @media (min-width: 1024px) {
    /* margin-top: 10px; */
    gap: 5rem;
    .container-range {
      gap: 1rem;
    }
    .container-results {
      width: 450px;
      margin: 0 auto;
      gap: 1.5rem;
      .results {
        justify-content: space-between;
      }
      .other-results {
        justify-content: space-between;
      }
    }
  }
`

const Details = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding: 0 1.2rem; */
  padding-bottom: 2rem;
  color: #aaa;

  .container-info-currency {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 1.3rem;
    }

    .label {
      font-size: 1.1rem;
    }
    .value {
      font-size: 1.5rem;
      margin-top: 3px;
      text-align: right;
      display: block;
    }
  }

  p {
    margin: 0;
    /* font-size: 1.2rem; */
  }
`
